@import "../fonts";

.HomeWelcome {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;


  &__title {
    @include font-pathway;
    @include font-weight-normal;
    font-size: 4rem;
    color: white;
    text-align: center;

    &:after {
      content: "";
      display: block;
      width: 8rem;
      height: 5px;
      margin: 3rem auto 0;
      background: rgba(216, 216, 216, 0.5);
    }
  }

  &__text {
    @include font-cardo;
    @include font-weight-normal;
    font-size: 1.7rem;
    line-height: 2.2;
    letter-spacing: 0.07em;
    color: rgba(255, 255, 255, 0.9);
    margin-top: -0.5rem;

    ul {
      padding: 0;
      margin: 0;
      display: flex;
      flex-direction: column;
    }

    li {
      display: inline-block;
      text-align: center;
    }

  }

  @media screen and (min-width: 640px) {
    ul {
      display: block;
    }

    li {
      display: inline-block;

      &:after {
        content: '·';
        display: inline-block;
        padding: 0 0.5em;
        color: #0C898A;
      }

      &:last-child:after {
        content: "";
      }
    }
  }

}