.HeroAvatar {
  position: relative;
  width: 264px;
  height: 264px;
  background: url("./images/avatar.png") no-repeat;

  &__eyes {
    position: absolute;
    top: 116px;
    left: 100px;
    width: 78px;
    height: 26px;
    background: {
      image:url("./images/avatar_blinking.png");
      repeat: no-repeat;
      position: 0 0;
    }
  }
}