.Header {
  > * {
    margin: 0 auto;
  }

  .HeroAvatar {
    transform: scale(0.85);
    margin-bottom: 2rem;

    @media screen and (min-width: 640px){
      transform: none;
      margin-bottom: 6rem;
    }
  }
}