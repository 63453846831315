@mixin font-default {
  font-family: Helvetica, Arial, sans-serif;
}

@mixin font-cardo {
  font-family: 'Cardo', serif;
}

@mixin font-pathway {
  font-family: 'Pathway Gothic One', sans-serif;
}

@mixin font-weight-normal {
  font-weight: 400;
}