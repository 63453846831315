:root {
  font-size: 10px;
  background: #202122;
}

html, body, #root {
  padding: 0;
  margin: 0;
  min-height: 100vh;
}



.Page_Home {
  display: flex;
  min-height: 100vh;
  align-items: center;
  justify-content: center;
}